
import { Component, Ref, Vue } from "vue-property-decorator";
import AttachmentsView from "@/components/AttachmentsView/index.vue";
import ExportWord from "@/components/DownloadWord/index.vue";
import AuditButtons from "@/components/AuditButtons/index.vue";
import { AuditBodyDto } from "@/utils/customDto";
import {
  InternalMonthlySummarizeDto,
  OrganizationUnitDto,
  AttachmentHostType,
  AuditFlowScope
} from "@/api/appService";
import api from "@/api";
import moment from "moment";
import FileList from "@/components/CustomForm/TableDetail/components/fileList.vue";

@Component({
  name: "internalMonthlySummarizeDetail",
  components: {
    FileList,
    AttachmentsView,
    ExportWord,
    AuditButtons
  },
  filters: {
    formatStatus(value:string, list:any[]) {
      var label =''
      if(list && list.length>0) {
         list.map((item:any)=>{
          if(item.value === value) {
            label =  item.label
          }
        })
      }
      return label
    }
  }
})
export default class internalMonthlySummarizeDetail extends Vue {
  @Ref() auditButtons!: AuditButtons;
  orgId?: number;
  detail: InternalMonthlySummarizeDto = {};
  unitList?: OrganizationUnitDto[] = [];
  selector: string = "table-detail";
  filename: string = "项目月总结表";
  styles: string = "";
  statusList = [
    {
      value: "None",
      label: "未展开"
    },
    {
      value: "Adjust",
      label: "调整"
    },
    {
      value: "Complete",
      label: "已完成"
    }
  ];
  get hostTypeFile() {
    return AttachmentHostType.Internal_Project_Monthly_Summarize_Attachment;
  }

  auditParams: AuditBodyDto = {
    hostId: undefined,
    hostType: AuditFlowScope.InternalMonthlySummarize,
    auditFlowId: undefined,
    auditStatus: undefined,
    audit: undefined
  };

  created() {
    if (this.$route.params.id) {
      this.orgId = Number(this.$route.params.id);
      this.fetchDetail();
    }
  }

  fetchAuditData() {
    return api.internalMonthlySummarize.audit;
  }

  // 获取详情
  async fetchDetail() {
    //合作方列表
    api.organizationUnit.getAll({ maxResultCount: 100 }).then((res: any) => {
      this.unitList = res.items!;
    });

    await api.internalMonthlySummarize.get({ id: this.orgId }).then(res => {
      let resData = res;
      this.detail = { ...resData };
      this.auditParams!.hostId = res.id + "";
      this.auditParams!.auditFlowId = res.auditFlowId;
      this.auditParams!.auditStatus = res.auditStatus;
      this.auditParams!.audit = res.audit;
      if (res.auditFlowId) {
        (this.auditButtons as any).fetchCanAudit();
      }
    });
  }

  formatPartnerOrgName(orgIds: string) {
    const displayNames: string[] = [];
    this.unitList!.map((item: any) => {
      if (orgIds.indexOf(item.id) > -1) {
        displayNames.push(item.displayName);
      }
    });
    return displayNames.join(",");
  }

  changeAudit() {
    this.fetchDetail();
  }

  // 返回
  private cancel() {
    this.$router.back();
  }
}
