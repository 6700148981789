
import {Component, Ref, Vue, Watch} from "vue-property-decorator";
import {
  InternalMonthlyPlanDto,
  InternalMonthlySummarizeCreateOrUpdateDto,
  InternalMonthlySummarizeDetailCreateOrUpdateDto,
  OrganizationUnitDto,
  AttachmentHostType,
  InternalProjectDto,
} from "@/api/appService";
import api from "@/api";
import {ElForm} from "element-ui/types/form";
import ProjectSelect from "@/components/ProjectSelect/index.vue";
import MultipleUploadFile from "@/components/MultipleUploadFile/index.vue";

@Component({
  name: "InternalMonthlySummarizeEdit",
  components: {
    ProjectSelect,
    MultipleUploadFile,
  },
})
export default class InternalMonthlySummarizeEdit extends Vue {
  @Ref() readonly dataForm!: ElForm;
  dataId!: number;
  monthlyPlayList: InternalMonthlySummarizeDetailCreateOrUpdateDto[] = [
    {
      id: undefined,

      title: undefined,

      sequence: undefined,

      place: undefined,

      /**  */
      startDate: undefined,

      /**  */
      endDate: undefined,

      /**  */
      summary: undefined,

      completeStatus: undefined,

      /**  */
      partnerOrgId: undefined,

      savedAttachments: [],

      /** 摘要 */
      remark: undefined,
    },
  ];
  statusList = [
    {
      value: "None",
      label: "未展开",
    },
    {
      value: "Adjust",
      label: "调整",
    },
    {
      value: "Complete",
      label: "已完成",
    },
  ];
  defaultData: InternalMonthlySummarizeCreateOrUpdateDto = {
    date: undefined,
    auditFlowId: undefined,
    projectId: undefined,
    internalMonthlyPlanId: undefined,
    //details: this.monthlyPlayList,
    details: [],
    id: 0,
  };
  projectType = "";
  projectList: InternalProjectDto[] = [];
  // 合作方机构列表
  partnerOrgList: OrganizationUnitDto[] = [];
  // 选择后合作方机构id
  partnerOrgIds = [];
  //
  unitList?: OrganizationUnitDto[] = [];
  planList?: InternalMonthlyPlanDto[] = [];
  form: InternalMonthlySummarizeCreateOrUpdateDto = {...this.defaultData};

  disabled: boolean = false;

  get hostTypeFile() {
    return AttachmentHostType.Internal_Project_Monthly_Summarize_Attachment;
  }

  // 选择月计划
  changeMonthlyPlan(e: number) {
    if (e) {
      api.internalMonthlyPlan
        .get({id: e})
        .then((res: InternalMonthlyPlanDto) => {
          if (res.details && res.details.length > 0) {
            this.form.details = res.details;
            this.form.details.map((item: any) => {
              item.savedAttachments = item.savedAttachments ?? [];
              item.internalMonthlyPlanDetailId = item.id;
              item.id = 0;
            });
          }
        });
    } else {
      this.form.details = [];
    }
  }

  // 添加一行
  private addRow(r: number) {
    let obj = {
      id: undefined,

      title: undefined,

      sequence: undefined,

      place: undefined,

      /**  */
      startDate: undefined,

      /**  */
      endDate: undefined,

      /**  */
      summary: undefined,

      /**  */
      partnerOrgId: undefined,

      /**  */
      needSupport: undefined,

      completeStatus: undefined,

      savedAttachments: [],

      internalMonthlyPlanDetailId: undefined,
      /** 摘要 */
      remark: undefined,
    };
    this.form.details = [...this.form.details!, obj];
  }

  // 删除整行
  private deleteRow(index: number) {
    this.form.details!.splice(index, 1);
  }

  created() {
    this.fetchProjectList();
    //
    this.showPlanListByProject("");
    this.fetchOrgAll();
    this.dataId = Number(this.$route.params.id);
    if (this.dataId) {
      this.disabled = true;
      this.fetchDetail();
    }
  }

  async fetchProjectList() {
    // await api.internalProject.getInternalProjectListByDate({ date: this.form.date + "-01" })
    await api.internalProject.getInternalProjectListByDate({date: ""})
      .then((res) => {
        console.log(1111111111);
        console.log(res);
        if (res) {
          this.projectList = res;
        }
      });
  }

  // 获取详情
  async fetchDetail() {
    await api.internalMonthlySummarize.get({id: this.dataId}).then((res) => {
      if (res.details && res.details.length > 0) {
        res.details.map((item: any) => {
          item.savedPartnerOrgIds = item.savedPartnerOrgIds.map(Number);
        });
      }
      console.log(res, "item");
      this.form = res!;
      this.showPlanListByProject("");
    });
  }

  // 获取机构
  async fetchOrgAll() {
    await api.organizationUnit.getAll({maxResultCount: 2000})
      .then((res: any) => {
        this.unitList = res.items;
      });
  }

  async showPlanList() {
    debugger
    if (this.form.date) {
      this.fetchProjectList();
    } else {
      this.projectList = [];
      this.form.projectId = undefined;
      this.form.details = [];
    }

    if (!this.dataId) {
      this.form.projectId = undefined;
      this.form.details = [];
    }
  }

  async showPlanListByProject(e: any) {
    if (e) {
      this.planList = [];
      this.form!.internalMonthlyPlanId = undefined;
      this.form.details = [];
    }
    if (this.form.date) {
      this.fetchProjectList();
    } else {
      //this.projectList = [];
    }

    if ((this.form.projectId ?? 0 > 0) && this.form.date) {
      await api.internalMonthlyPlan.getMyInternalMonthlyPlanList({
        projectId: this.form.projectId,
        //date: this.form.date + "",
      })
        .then((res: any) => {
          this.planList = res!;
        });
    }
  }

  cancel() {
    this.$router.go(-1);
  }

  async save() {
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (this.dataId) {
          await api.internalMonthlySummarize.update({
            body: {...this.form},
          });
        } else {
          await api.internalMonthlySummarize.create({
            body: {...this.form},
          });
        }
        this.$message.success("更新成功");
        this.$router.go(-1);
      } else {
        this.$message({
          type: "error",
          message: "请检查表单",
        });
      }
    });
  }

  roleRule = {
    date: [
      {
        required: true,
        message: "请选择月份",
        trigger: "blur",
      },
    ],
    projectId: [
      {
        required: true,
        message: "请选择所属项目",
        trigger: "blur",
      },
    ],
    internalMonthlyPlanId: [
      {
        required: true,
        message: "请选择对应月计划",
        trigger: "blur",
      },
    ],
  };
  detailRule = {
    title: [
      {
        required: true,
        message: "请输入活动主题",
        trigger: "change",
      },
    ],
    place: [
      {
        required: true,
        message: "请输入活动地点",
        trigger: "change",
      },
    ],
    publishTime: [
      {
        required: true,
        message: "请选择发布时间",
        trigger: "change",
      },
    ],
    startDate: [
      {
        required: true,
        message: "请选择开始日期",
        trigger: "change",
      },
    ],
    endDate: [
      {
        required: true,
        message: "请选择结束日期",
        trigger: "change",
      },
    ],
    benefitObjectCount: [
      {
        required: true,
        message: "请填写受益人次",
        trigger: "change",
      },
      {
        type: "number",
        message: "受益人次必须为数字",
        trigger: "change",
      },
    ],
    completeStatus: [
      {
        required: true,
        message: "请选择状态",
        trigger: "change",
      },
    ],
    summary: [
      {
        required: true,
        message: "请输入原计划",
        trigger: "change",
      },
    ],
    executeSummary: [
      {
        required: true,
        message: "请输入执行概述",
        trigger: "change",
      },
    ],
  };
}
