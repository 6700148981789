
  import {exportWord} from '@/utils/htmlToWord'
  import {Component, Prop, Vue} from "vue-property-decorator";
  import $ from 'jquery';

  @Component({
    name: "ExportWord"
  })


  export default class extends Vue {

    @Prop({required: true})
    selector!: string

    @Prop({required: true})
    filename!: string

    @Prop({required: true})
    styles!: string

    loading: boolean = false

    handleDownload() {



      this.loading = true;
      const selector = '.' + this.selector;

      const now = new Date();
      const id = `export-table-${now.getFullYear()}${now.getMonth()}${now.getDay()}${now.getHours()}${now.getMinutes()}${now.getSeconds()}${now.getMilliseconds()}`;

      const html = `<div style="display: none;" id="${id}">${$(selector).html()}</div>`;
      $('body').append(html);
      const newSelector = '#'+id;
      const $wrap = $(newSelector);
      $wrap.find('td.field-name,td.font-bold').css('background-color','#d3d3d3');
      $wrap.find('.tr-td-colspan-row').remove();
      $wrap.find('table').css('margin-bottom','20px').after('<p>&nbsp;</p>');
      $wrap.find('button.el-button').remove();

      exportWord({
        selector: newSelector,
        filename: this.filename,
        style: this.styles == "" ? this.defaultStyle() : this.styles
      });
      $wrap.remove();
      this.loading = false;
    }


    defaultStyle() {

      let style = "";
      style +=
        "table{border-collapse: collapse;width:100%;max-width:100%;} table td{height:34px;text-align:center;border:1px solid windowtext;font-size:14px;padding:5px;word-break: break-word;width: 80px!important;} table td.text-left{text-align:left;padding:5px 10px;}.text-center{text-align: center;}"
      return style;
    }

  }


