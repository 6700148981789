import { saveAs } from 'file-saver'

function getModelHtml(mhtml:any, style:string) {
  return`
      <!DOCTYPE html>
      <html>
      <head>
      <meta http-equiv="Content-Type" content="text/html;charset=utf-8"/>
      <style>
        ${style}
      </style>
      </head>
      <body>
        ${mhtml}
      </body>
      </html>
    `
}
interface ExportWordArguments {
  mhtml?:any;
  style?:any;
  filename?:string,
  data?:string,
  selector:string
}
export function exportWord(args:ExportWordArguments) {
  if(typeof module !== 'undefined'){
    window.saveAs = require('file-saver')
  }

  if(args.selector){
    let nodes = window.document.querySelectorAll(args.selector)
    args.mhtml = nodes.length>0?Array.from(nodes).reduce((a,b)=>a+b.innerHTML,''):''
  }

  if (typeof saveAs === "undefined") {
    console.error("wordExport : missing dependency (FileSaver.js)");
    return;
  }
  let html = getModelHtml(args.mhtml,args.style)
  console.log("Html="+JSON.stringify(html))
  let blob = new Blob([html],{type:'application/msword;charset=utf-8'})
  saveAs(blob,args.filename+'.doc')
}
