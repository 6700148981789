
  import {Component, Prop, Ref, Vue} from "vue-property-decorator";
  import RejectDialog from "@/components/RejectDialog/index.vue";
  import {AuditFlowScope, AuditUserLogStatus, AuditUserLog} from "@/api/appService";
  import api from '@/api';

  @Component({
    name: 'AuditButtons', components: {
      RejectDialog
    }
  })
  export default class AuditButtons extends Vue {
    @Ref() backDialog!: RejectDialog;

    @Prop({required: true})
    auditFunction!: Function;

    @Prop({required: true})
    auditParams!: any

    @Prop({required: false, default:""})
    size!: string;

    private body:AuditUserLog = {
      hostId: undefined,
      hostType: undefined,
      auditFlowId: undefined,
      status: undefined,
      auditStatus: undefined,
      desc: undefined
    };
    isCanAudit:boolean = false;

    submitting = false;

    disabled = false;

    isAuditCompleted = false;

    // created() {
    //   this.fetchCanAudit()
    // }

    fetchCanAudit() {
      const canAuditParams = {
        auditFlowId: this.auditParams.auditFlowId,
        auditStatus: this.auditParams.auditStatus,
        audit: this.auditParams.audit
      }
      api.auditFlow.canAudit({body:canAuditParams}).then(res=>{
        this.isCanAudit = res;
        this.isAuditCompleted = false;
        this.disabled = false;
      })
    }

    handleAudit(value:string){
      this.body.hostId = this.auditParams.hostId;
      this.body.hostType = this.auditParams.hostType;
      this.body.auditFlowId = this.auditParams.auditFlowId;
      this.body.auditStatus = this.auditParams.auditStatus;
      if(value === 'pass') {
        this.body.status = AuditUserLogStatus.Pass;
        this.body.desc='审核通过';
        this.submitting = true;
        this.disabled = true;
        this.auditFunction.call(this).call(this,{body: this.body}).then((res: any) => {
          this.$message({
            type: "success",
            message: "操作成功!"
          });
          this.submitting = false;
          this.isAuditCompleted = true;
          this.$emit('change');
        }).catch((err:any)=>{
          this.submitting = false;
          this.disabled = false;
        });
      } else if (value === 'back'){
        this.body.status = AuditUserLogStatus.Back;
        (this.backDialog as any).show = true;
      } else if (value === 'reject'){
        this.body.status = AuditUserLogStatus.Reject;
        (this.backDialog as any).show = true;
      }
    }

    backed(text: string) {
      this.body.desc = text;
      this.submitting = true;
      this.auditFunction.call(this).call(this,{body: this.body}).then(() => {
        this.$message.success('操作成功')
        this.$emit('change');
        this.submitting = false;
        this.disabled = false;
        this.isAuditCompleted = true;
      });
    }
  }
