
  import {Component, Prop, Vue, Model, Watch} from "vue-property-decorator";
  import {AttachmentDto, AttachmentHostType} from "@/api/appService";
  import api from '@/api/index'
  import {UserModule} from "@/store/modules/user";
  import {AppModule} from "@/store/modules/app";
  import {interfaceTemplate} from "swagger-vue-codegen/dist/templates/template";

  interface FileItem{
    id:number,
    name:string,
    url:string
  }

  @Component({name:"fileList"})
  export default class fileList extends  Vue{

    @Prop({required:true})
    hostId!:string | number;

    @Prop({required:true})
    hostType!:AttachmentHostType;


    private existsFileList:FileItem[] = [];


    get headers(){
      return {
        'Abp.OrganizationUnitId':UserModule.getOU?.id,
        'Abp.TenantId':AppModule.getTenantId,
        'Authorization':`Bearer ${UserModule.getToken}`,
        '.AspNetCore.Culture':'c=zh-Hans|uic=zh-Hans'
      }
    }

    // @Watch('hostId')
    // onHostIdChange(){
    //   this.loadExistsAttachments();
    // }
    //
    // @Watch('hostType')
    // onHostTypeChange(){
    //   this.loadExistsAttachments();
    // }


    async created(){
      await this.loadExistsAttachments();
    }

    async loadExistsAttachments(){
      await api.attachmentService.getAttachments({hostId:this.hostId.toString(),hostType:this.hostType}).then(res=>{
        if(res){
          this.existsFileList = res.map(attachment=>{
            return {
              id:attachment.id!,
              url:attachment.url!,
              name:attachment.fileName!
            }
          });
        } else {
          this.existsFileList = [];
        }
      })
    }


  }

