
import { Component, Prop } from "vue-property-decorator";

interface ISoringPagedResultDto {
  sorting: string;
  maxResultCount: number;
  skipCount: number;
}

import { Vue } from "vue-property-decorator";
import { constants } from "http2";
import {useRoute, useRouter} from "vue-router/composables";
import Cookies from "js-cookie";

@Component({ name: "PagedTableView" })
export default class PagedTableView extends Vue {
  @Prop()
  queryForm: any;

  @Prop({ required: true })
  searchFunction!: Function;

  @Prop({})
  pageSizes!: Array<number>;

  @Prop({ required: false, default: 10 })
  pageSize!: number;

  @Prop({ required: false, default: 0 })
  showTotalMoney!: number;
  @Prop({ required: false, default: 0 })
  totalMoney!: number;

  @Prop()
  resetForm!: Function;

  @Prop({ required: false, default: false })
  hiddenQueryForm!: boolean;

  get pageSizeChoose() {
    if (this.pageSizes && this.pageSizes.length) {
      return this.pageSizes;
    } else {
      return [10, 20, 50, 100];
    }
  }

  // private fields
  tableItems: any = [];
  table: any = {
    listLoading: true,
    page: 1,
    totalCount: 0,
    pageSize: 0,
    sorting: ""
  };

  created() {
    this.table.pageSize = this.pageSize;

    let cache = Cookies.get('PageInfoCache');
    if(cache){
      let routeName=this.$route.name;
      let cacheObj=JSON.parse(cache);
      let pageInfo=cacheObj[routeName!];
      if(pageInfo){
        this.table.page=pageInfo['pageIndex'];
        this.table.pageSize=pageInfo['pageSize']
      }
    }


    this.fetchData();

    let $bus = (this as any).$bus;
    if ($bus) {
      $bus.$on("data-updated", () => this.fetchData());
      $bus.$on("data-created", () => this.fetchData());
      $bus.$on("data-deleted", () => this.fetchData());
      $bus.$on("api-action-completed", () => this.fetchData());
    }
  }

  beforeDestroy(){

  }

  handleSelectionChange(selection: any[]) {
    this.$emit("selectionList", selection);
  }

  refreshData() {
    console.log("pagedTableView:refreshData");
    this.table.page = 1;
    this.fetchData();
  }

  fetchData() {
    const pageParameter: ISoringPagedResultDto = {
      skipCount: (this.table.page - 1) * this.table.pageSize,
      maxResultCount: this.table.pageSize,
      sorting: this.table.sorting
    };
    this.table.listLoading = true;

    const fetchResult = this.searchFunction(
      Object.assign({}, this.queryForm, pageParameter)
    );
    fetchResult.then((res: any) => {
      this.table.listLoading = false;
      this.tableItems = res.items!;
      this.table.totalCount = res.totalCount!;
    });

    return fetchResult;
  }

  // events handles
  handleSearch() {
    this.table.page = 1;
    this.fetchData();
  }

  handleResetSearch() {
    if (this.resetForm !== null && typeof this.resetForm === "function") {
      this.resetForm();
    } else {
      if (this.queryForm) {
        for (let key in this.queryForm) {
          this.queryForm[key] = undefined;
        }
      }
    }
    this.refreshData();
  }

  // 更新当前页
  async current_change(e: number) {
    let routeName=this.$route.name;
    let cache = Cookies.get('PageInfoCache');
    if(cache){
      let cacheObj=JSON.parse(cache);
      cacheObj[routeName!]={pageIndex:e,pageSize:this.table.pageSize};
      Cookies.set('PageInfoCache',JSON.stringify(cacheObj))
    }
    else{
      let obj={} as any;
      obj[routeName!]={pageIndex:e,pageSize:this.table.pageSize};
      Cookies.set('PageInfoCache',JSON.stringify(obj))
    }

    this.table.page = e;
    await this.fetchData();
  }

  // Table排序
  async sort(e: any) {
    console.log("sort : ", e);
    if (e.prop && e.order) {
      this.table.sorting = `${e.prop} ${e.order}`;
    }
    this.fetchData();
  }

  // 修改一页显示的条目
  handleSizeChange(e: number) {
    let routeName=this.$route.name;
    let cache = Cookies.get('PageInfoCache');
    if(cache){
      let cacheObj=JSON.parse(cache);
      cacheObj[routeName!]={pageIndex:this.table.page,pageSize:e};
      Cookies.set('PageInfoCache',JSON.stringify(cacheObj))
    }
    else{
      let obj={} as any;
      obj[routeName!]={pageIndex:this.table.page,pageSize:e};
      Cookies.set('PageInfoCache',JSON.stringify(obj))
    }


    this.table.pageSize = e;
    this.fetchData();
  }
}
